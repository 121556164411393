import React from 'react'
import { graphql } from 'gatsby'
import { ArticlePageContext } from 'gatsby-node';
import { ArticleTemplateQuery } from '../../types/graphql-types'
import { Layout } from '../components/layout'

interface PageProps {
  data: ArticleTemplateQuery;
  pageContext: ArticlePageContext
}

const Page: React.FC<PageProps> =({ data }) => (
  <Layout>
    <h1 className="article-title">
      {data.markdownRemark?.frontmatter?.title ?? '(無題)'}
    </h1>
    {data.markdownRemark?.frontmatter?.date && (
      <p className="article-date">
        {data.markdownRemark?.frontmatter?.date} 投稿
      </p>
    )}
    <hr />
    <div 
      className="article-body"
      dangerouslySetInnerHTML={{__html: data.markdownRemark?.html ?? ''}}
    />
  </Layout>
)
export default Page

export const query = graphql`
  query ArticleTemplate($slug: String!) {
    markdownRemark(frontmatter: { slug: {eq: $slug }}) {
      html
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD", locale: "ja-JP")
      }
    }
  }
`